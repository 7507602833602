import React from 'react';
import About from './components/About';
import Banner from './components/Banner';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Header from './components/Header';
import InfoCarousel from './components/InfoCarousel';
import Services from './components/Services';
import Vision from './components/Vision';

const App = () => {
	return (
		<div>
			<Header />
			<main>
				<Banner />
				<Vision />
				<Services />
				<InfoCarousel />
				<About />
				<ContactUs />
			</main>
			<Footer />
		</div>
	);
};

export default App;
